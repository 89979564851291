import React, { useState, useEffect } from "react"
import { Checkbox, FormControlLabel, Grid } from "@material-ui/core"
import { dialogCodeToDialogContent } from "@/components/UpsellDialog"

import { ActionDialog } from "@/components"

import useSubscriptionLimits from "@/hooks/useSubscriptionLimits"

import StorageService from "@/services/Storage"
import DateService from "@/services/Date"

import { DAY_IN_MS } from "@/utils/time"

type UserPreference = {
	doNotShowPopupAgain: boolean
	preferenceExpiresAt: Date
}

const USER_PREFERENCE_EXPIRATION_IN_DAYS = 15

const USER_PREFERENCE_STORAGE_KEY = "inbox:user-preference:ai-text-enhancement-admin-upsell-dialog"

const AITextEnhancementAdminUpsellDialog: React.FC = () => {
	const aiTextEnhancementQuantityLimit = useSubscriptionLimits("quantity", "ai_text_enhancement")

	const [isDialogOpen, setIsDialogOpen] = useState(false)
	const [isCheckboxChecked, setIsCheckboxChecked] = useState(false)

	const saveUserPreference = () => {
		if (isCheckboxChecked) {
			const userPreferenceExpirationInMilliseconds = DAY_IN_MS * USER_PREFERENCE_EXPIRATION_IN_DAYS
			const preferenceExpiresAt = new Date(DateService.currentWorldDate().getTime() + userPreferenceExpirationInMilliseconds)

			StorageService.set<UserPreference>(USER_PREFERENCE_STORAGE_KEY, {
				doNotShowPopupAgain: true,
				preferenceExpiresAt
			})
		}
	}

	const canShowDialog = () => {
		if (!aiTextEnhancementQuantityLimit.mustBlock) {
			return false
		}

		const hasExpectedDialogBlockCode = aiTextEnhancementQuantityLimit.blockCode === "ai-text-enhancement-limit-reached-admin"

		if (!hasExpectedDialogBlockCode) {
			return false
		}

		const userPreference = StorageService.get<UserPreference>(USER_PREFERENCE_STORAGE_KEY)
		const isUserPreferenceExpired = userPreference && (new Date(userPreference.preferenceExpiresAt) < DateService.currentWorldDate())

		if (userPreference?.doNotShowPopupAgain && !isUserPreferenceExpired) {
			return false
		}

		return true
	}

	const handleCloseDialog = () => {
		saveUserPreference()
		setIsDialogOpen(false)
	}

	const handleSaveDialog = () => {
		window.open(dialogCodeToDialogContent["ai-text-enhancement-limit-reached-admin"].link, "_blank")
		handleCloseDialog()
	}

	const onCheckboxToggle = () => {
		setIsCheckboxChecked(prev => !prev)
	}

	useEffect(() => {
		if (canShowDialog()) {
			setIsDialogOpen(true)
		}
	}, [aiTextEnhancementQuantityLimit.mustBlock])

	return (
		<ActionDialog
			onClose={handleCloseDialog}
			onSave={handleSaveDialog}
			saveText="CONTRATAR AGORA"
			openDialog={isDialogOpen}
			title={dialogCodeToDialogContent["ai-text-enhancement-limit-reached-admin"].dialogueTitle}
			maxWidth="sm"
		>
			<Grid container spacing={2}>
				<Grid item>
					{dialogCodeToDialogContent["ai-text-enhancement-limit-reached-admin"].dialogueBody}
				</Grid>

				<Grid item>
					<FormControlLabel
						control={
							<Checkbox
								size="small"
								checked={isCheckboxChecked}
								onChange={onCheckboxToggle}
							/>
						}
						label="Marcar como lido e exibir novamente daqui 15 dias"
					/>
				</Grid>
			</Grid>
		</ActionDialog>
	)
}

export default AITextEnhancementAdminUpsellDialog
