import React from "react"

import BaseMessage from "@/components/ACExternalConversationPanel/MessageList/CommonMessage/BaseMessage"

import { Messages } from "@/components"
import { useActiveCampaignExternalChatGlobalStateStore } from "@/store/ActiveCampaignExternalChatGlobalState"

type CommonMessageProps = {
	messageId: string
}

const CommonMessage: React.FC<CommonMessageProps> = (props) => {
	const {
		messageId
	} = props

	const activeCampaignExternalChatGlobalState = useActiveCampaignExternalChatGlobalStateStore()
	const message = activeCampaignExternalChatGlobalState.message.getById(messageId)

	return (
		<BaseMessage
			message={message}
			messageContentBoxStyle={(
				Messages.getMessageContentBoxStyle(message.type)
			)}
			childContent={(
				Messages.getChildContent(message.type, {
					fileName: message.fileName,
					caption: message.caption,
					content: message.content,
					uploadingMedia: message.uploadingMedia,
					mediaUploadProgress: message.uploadProgressInPercentage,
					fancyBoxSlug: String(message.inboxChannelChatId),
					copySpecial: false,
					translateVariables: false,
					extraData: message.extraData,
					/**
					 * Today, message sending feature doesnt treat errors
					 * We only have generic errors then
					 * Because of that, we need to add a generic error into that property
					 */
					messageErrorCode: message.status === "not-sent" ? "error" : ""
				})
			)}
			childContentFooter={(
				Messages.getChildContentFooter(message.type, {
					extraData: message.extraData
				})
			)}
			childContainerFooter={(
				Messages.getChildContainerFooter(message.type, {
					extraData: message.extraData,
					sendByCustomer: message.sentByCustomer,
					status: message.status
				})
			)}
		/>
	)
}

export default CommonMessage
