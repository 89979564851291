import StorageService from "@/services/Storage"
import ApiService from "@/services/Api"

export type PlanAddonsInAccountStatus = "active" | "finished"
export type PlanAddonsInAccountCreatedBy = "user" | "admin"

export type CreatePlanAddonsInAccountInput = {
	planAddonId: number,
	createdBy: PlanAddonsInAccountCreatedBy
}

export type CreatePlanAddonsInAccountResponse = {
	id: number
	account_id: number
	plan_addon_id: number
	status: PlanAddonsInAccountStatus
	apps_instance_id: number
	created_by: PlanAddonsInAccountCreatedBy
	updated_at: Date
	created_at: Date
	deleted_at: Date
}
class AccountService {
	get accountKeyPrefix (): string {
		return "@account/inbox"
	}

	setStorage (key: string, value: string | number): void {
		StorageService
			.set(`${this.accountKeyPrefix}/${key}`, value)
	}

	getStorage<ExpectedData> (key: string): ExpectedData | null {
		return StorageService
			.get(`${this.accountKeyPrefix}/${key}`)
	}

	async createPlanAddonsInAccount (
		data: CreatePlanAddonsInAccountInput
	): Promise<{planAddonInAccountCreated: CreatePlanAddonsInAccountResponse}> {
		const response = await ApiService.post("/account/plan-addons-in-account", {
			...data
		})

		return response.data
	}
}

export default new AccountService()
