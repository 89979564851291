import React from "react"
import clsx from "clsx"
import { Grid, Typography, Chip } from "@material-ui/core"

import { WABAChannelChatCommunicationPermissionStatus } from "@/@integrations/WABA/protocols/wabaChannelChatCommunicationPermission"
import { fullDatetime, getDifferenceInHours } from "@/utils/time"

import useStyles from "@/@integrations/WABA/components/Chat/WABAChatCommunicationPermissionBar/styles"
import HardCoded from "@/services/HardCoded"

type WABAChatCommunicationPermissionBarProps = {
	status: WABAChannelChatCommunicationPermissionStatus
	scheduledPauseAt?: Date
}

const WABAChatCommunicationPermissionBar: React.FC<WABAChatCommunicationPermissionBarProps> = (props) => {
	const classes = useStyles()

	const getStatusMessage = () => {
		const statusToMessage: Record<WABAChannelChatCommunicationPermissionStatus, string> = {
			active: "Permissão de comunicação da WABA ativa",
			paused: "Permissão de comunicação da WABA pausada"
		}

		return statusToMessage[props.status]
	}

	const canShowPauseDate = props.status === "active" && props.scheduledPauseAt

	const scheduledPauseAt = new Date(props.scheduledPauseAt as Date)

	const communicationTimeoutWarning = HardCoded.checkFeatureFlag("wabaCommunicationPermissionBarWarning") && (scheduledPauseAt && getDifferenceInHours(scheduledPauseAt, new Date()) <= 4)

	return (
		<Grid
			container
			alignItems="center"
			justifyContent="space-between"
			className={classes.container}
		>
			<Grid item>
				<Grid
					container
					spacing={1}
					alignItems="center"
				>
					<Grid item>
						<Grid
							className={clsx({
								[classes.statusIcon]: true,
								[classes.statusIconActive]: props.status === "active",
								[classes.statusIconAlert]: communicationTimeoutWarning
							})}
						/>
					</Grid>
					<Grid item>
						<Typography
							variant="body1"
							className={classes.statusMessage}
						>
							{getStatusMessage()}
						</Typography>
					</Grid>
				</Grid>
			</Grid>

			{canShowPauseDate && (
				<Grid item>
					<Chip
						label={`${communicationTimeoutWarning ? "Expira em breve: " + fullDatetime(scheduledPauseAt) : "Ativa até " + fullDatetime(scheduledPauseAt)}`}
						className={clsx({
							[classes.pauseDateChip]: true,
							[classes.alertDateChip]: communicationTimeoutWarning
						})}
					/>
				</Grid>
			)}
		</Grid>
	)
}

export default WABAChatCommunicationPermissionBar
