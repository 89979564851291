import React, { useRef, useState } from "react"
import { useHistory } from "react-router-dom"
import {
	Button,
	ButtonGroup,
	Grid,
	Grow, InputLabel,
	MenuItem,
	MenuList,
	Paper,
	Popper, TextField
} from "@material-ui/core"

import {
	Add as AddIcon,
	ArrowDropDown
} from "@material-ui/icons"

import { ActionDialog, Divider, InfoDialog, JsonEditor, Notification } from "@/components"

import useValidation, { ErrorType } from "@/hooks/useValidation"

import { ShortFlow } from "@/protocols/chatBotConstructor"
import { ChatBotFlow } from "@/protocols/chatBot"

import ChatBotFlowService from "@/services/ChatBotFlow"
import ErrorHandlerService from "@/services/ErrorHandler"

import { ChatBotTabType } from "@/pages/Admin/ChatBot/ChatBotList"
import NoteController from "@/components/NoteDialog/controllers/note"
import ChatBotTemplate from "@/pages/Admin/Flow/ChatBotCreation/ChatBotTemplate"

import useCustomStyles from "@/styles/custom"
import DisableComponentByChannel from "@/components/DisableComponentByChannel"

type ChatBotCreationProps = {
	chatBotType?: ChatBotTabType
	onChatBotCreated?: () => Promise<void>
}

const ChatBotCreation: React.FC<ChatBotCreationProps> = (props) => {
	const {
		chatBotType,
		onChatBotCreated
	} = props

	const customClasses = useCustomStyles()

	const [open, setOpen] = useState<boolean>(false)
	const [importChatBotDialog, setImportChatBotDialog] = useState<{ open: boolean, loading: boolean }>({ open: false, loading: false })

	const [newChatBotDialog, setNewChatBotDialog] = useState<{ open: boolean, loading: boolean }>({ open: false, loading: false })
	const [chatBotData, setChatBotData] = useState<Pick<ChatBotFlow, "name"> & { description?: string }>({} as ChatBotFlow & { description?: string })

	const history = useHistory()
	const anchorRef = useRef(null)
	const { clearValidation, triggerValidation, validation } = useValidation()

	const disabledButton = chatBotType === "bots-v1"

	const handleToggle = () => {
		setOpen((prevOpen) => !prevOpen)
	}

	const handleCloseImportChatBotDialog = () => {
		setImportChatBotDialog({ open: false, loading: false })
	}

	const handleOpenImportChatBotDialog = () => {
		setImportChatBotDialog({ open: true, loading: false })
	}

	const handleImportChatBot = async (chatBotFlow: ShortFlow) => {
		setImportChatBotDialog({ ...importChatBotDialog, loading: true })

		try {
			const importedChatBotFlow = await ChatBotFlowService.import(chatBotFlow)

			if (importedChatBotFlow.type === "flow") {
				history.push(`/admin/flow/${importedChatBotFlow.id}`)
			} else {
				history.push(`/admin/bots/${importedChatBotFlow.id}`)
			}

			Notification.success({ message: "Fluxo do bot importado com sucesso!" })
		} catch (error) {
			ErrorHandlerService.handle(error as ErrorType)
			Notification.error({ message: "Não conseguimos importar o fluxo desse bot" })
		}

		setImportChatBotDialog({ ...importChatBotDialog, loading: false })
	}

	const handleOpenNewChatBotDialog = () => {
		setNewChatBotDialog({ ...newChatBotDialog, open: true })
	}

	const handleCloseNewChatBotDialog = () => {
		setNewChatBotDialog({ open: false, loading: false })
	}

	const handleCreateChatBot = async (data: (Pick<ChatBotFlow, "name">) & { description?: string }) => {
		setNewChatBotDialog({ ...newChatBotDialog, loading: true })

		let isOpen: boolean

		try {
			const chatBotFlow = await ChatBotFlowService.create("flow", {
				name: data?.name
			}, "chat-bot")

			if (data.description) {
				await NoteController.createNote("chat-bot", {
					chat_bot_flow_id: chatBotFlow.id,
					content: data.description
				})
			}

			window.open(`/admin/flow/${chatBotFlow.id}`, "_blank")

			await onChatBotCreated?.()

			isOpen = false
		} catch (error) {
			triggerValidation(error as ErrorType)
			isOpen = true
		}

		setNewChatBotDialog({ loading: false, open: isOpen })
	}

	const handleChangeNewChatBotData = (key: (keyof Pick<ChatBotFlow, "name">) | "description", value: string) => {
		setChatBotData(lastState => ({
			...lastState,
			[key]: value
		}))

		clearValidation(key)
	}

	return (
		<Grid container alignItems="center" justifyContent="flex-start">
			<Grid item xs={12}>
				<ButtonGroup variant="contained" color="primary" ref={anchorRef} aria-label="split button" disabled={disabledButton}>
					<Button
						variant="contained"
						color="primary"
						onClick={handleOpenNewChatBotDialog}
						startIcon={<AddIcon />}
					>
						Adicionar Bot
					</Button>
					<Button
						color="primary"
						size="small"
						aria-controls={open ? "split-button-menu" : undefined}
						aria-expanded={open ? true : undefined}
						aria-label="select merge strategy"
						aria-haspopup="menu"
						onClick={handleToggle}
					>
						<ArrowDropDown />
					</Button>
				</ButtonGroup>
				<Popper
					open={open}
					anchorEl={anchorRef.current}
					role={undefined}
					transition
					disablePortal
					style={{
						zIndex: "1"
					}}
				>
					{({ TransitionProps }) => (
						<Grow
							{...TransitionProps}
							style={{
								transformOrigin: "center bottom"
							}}
						>
							<Paper>
								<MenuList id="split-button-menu">
									<Grid alignItems="center" justifyContent="space-between" style={{ display: "flex" }}>
										<DisableComponentByChannel
											variant="with-icon"
										>
											<MenuItem
												onClick={handleOpenImportChatBotDialog}
											>
												Importar Bot
											</MenuItem>
										</DisableComponentByChannel>
									</Grid>

									{chatBotType !== "bots-v1" && (
										<ChatBotTemplate>
											<MenuItem>
												Bot a partir de um template
											</MenuItem>
										</ChatBotTemplate>
									)}
								</MenuList>
							</Paper>
						</Grow>
					)}
				</Popper>
			</Grid>

			<InfoDialog
				title="Importar Bot"
				openDialog={importChatBotDialog.open}
				onClose={handleCloseImportChatBotDialog}
			>
				<Grid container>
					<Grid xs={12}>
						<JsonEditor
							onSave={async (data) => await handleImportChatBot(data as unknown as ShortFlow)}
							placeholder="Cole aqui o código do Bot que deseja importar!"
							height="300px"
						/>
					</Grid>
				</Grid>
			</InfoDialog>

			<ActionDialog
				saveButtonId="add-new-chat-bot-save-button"
				title="Novo bot"
				saveText="SALVAR"
				openDialog={newChatBotDialog.open}
				onSave={() => handleCreateChatBot(chatBotData)}
				onClose={handleCloseNewChatBotDialog}
				loading={newChatBotDialog.loading}
				fullWidth
			>
				<Grid
					container
				>
					<Grid
						direction="column"
						container
						xs={12}
						spacing={2}
					>
						<Grid
							item
							xs
						>
							<InputLabel
								className={customClasses.inputLabel}
							>
								Defina um nome para esse bot
							</InputLabel>

							<Divider orientation="horizontal" size={1} />

							<TextField
								id="new-chat-bot-name"
								name="name"
								variant="outlined"
								placeholder="Ex.: Pré-atendimento"
								helperText={validation.name}
								error={!!validation.name}
								value={chatBotData?.name}
								onChange={({ target }) => handleChangeNewChatBotData("name", target.value)}
								fullWidth
							/>
						</Grid>

						<Grid
							item
							xs
						>
							<InputLabel
								className={customClasses.inputLabel}
							>
								Descrição do Bot(opcional):
							</InputLabel>

							<Divider orientation="horizontal" size={1} />

							<TextField
								id="new-chat-bot-description"
								name="description"
								variant="outlined"
								placeholder="Ex.: Bot para atender clientes do suporte"
								helperText={validation.description}
								error={!!validation.description}
								value={chatBotData?.description}
								minRows={6}
								multiline={true}
								onChange={({ target }) => handleChangeNewChatBotData("description", target.value)}
								fullWidth
							/>
						</Grid>
					</Grid>
				</Grid>
			</ActionDialog>
		</Grid>
	)
}

export default ChatBotCreation
