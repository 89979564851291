import React, { ComponentType } from "react"
import { NodeProps } from "reactflow"

import { BlockNodeData } from "@/protocols/chatBotFlow"

import useChatBotFlowBlockDetails from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/hooks/useChatBotFlowBlockDetails"
import useChatBotFlowConstructorStore from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/hooks/useChatBotFlowConstructorStore"

import BaseBlock from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/BaseBlock"
import ElseConditionSection from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/ElseConditionSection"
import EditTimeoutConditionSection from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/EditTimeoutConditionSection"
import BlockBodySectionGroup from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/BlockBodySectionGroup"
import WABATemplateMessageSectionEditor from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/WABATemplateMessageSectionEditor"

import {
	getCurrentWABATemplate
} from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/utils/chatBotFlowBlockContent"

const SendWABATemplateMessageAndWaitBlock: ComponentType<NodeProps<BlockNodeData>> = (node) => {
	const chatBotFlowBlockDetails = useChatBotFlowBlockDetails({ chatBotFlowBlockId: node.data.chatBotFlowBlockId })
	const chatBotFlowConstructorStore = useChatBotFlowConstructorStore()

	const currentWABAMessageTemplate = getCurrentWABATemplate(chatBotFlowConstructorStore.constructionResources, chatBotFlowBlockDetails.content)

	const availableWabaChannelMessageTemplates = chatBotFlowConstructorStore.constructionResources.wabaChannelMessageTemplates
		.filter(template => template.message?.type === "text")

	const handleGetWABAChannelMessageTemplates = async () => {
		await chatBotFlowConstructorStore.loadSpecificConstructionResources("wabaChannelMessageTemplates")
	}

	return (
		<BaseBlock
			node={node}
		>
			<WABATemplateMessageSectionEditor
				onSave={(wabaChannelMessageTemplate) => chatBotFlowBlockDetails.changeContent({
					wabaChannelMessageTemplate: {
						id: wabaChannelMessageTemplate.id
					}
				})}
				chatBotFlowBlockContent={chatBotFlowBlockDetails.content}
				wabaChannelMessageTemplate={currentWABAMessageTemplate}
				availableWabaChannelMessageTemplates={availableWabaChannelMessageTemplates}
				onOpen={handleGetWABAChannelMessageTemplates}
			/>

			<BlockBodySectionGroup>
				<ElseConditionSection
					nextChatBotFlowBlockRules={chatBotFlowBlockDetails.nextChatBotFlowBlockRules}
					text="Próximo passo após responder"
				/>

				<EditTimeoutConditionSection
					nextChatBotFlowBlockRules={chatBotFlowBlockDetails.nextChatBotFlowBlockRules}
					onSave={(chatBotFlowBlockRule) => chatBotFlowBlockDetails.changeRule("UPDATE", chatBotFlowBlockRule)}
					renderText={(timeText) => <span>{timeText} sem interação</span>}
					chatBotFlowChannelType={node.data.chatBotFlowChannelType}
				/>
			</BlockBodySectionGroup>
		</BaseBlock>
	)
}

export default SendWABATemplateMessageAndWaitBlock
